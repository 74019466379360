<template>
  <div-wrapper>
    <div-wrapper className="modal-overlaybg open"></div-wrapper>
    <div-wrapper
      id="confirm-modal"
      className="modal_pageview slideup modelslideup"
    >
      <div-wrapper className="confirm-box" :class="boxClass">
        <span class="close_pop" @click="$emit('handle-close-click')"
          ><close-icon
        /></span>
        <slot name="body"></slot>
        <div-wrapper className="confirm-bot">
          <slot name="btns"></slot>
          <span
            class="tab-item"
            :class="btnClass"
            @click="$emit('action', btnTxt)"
          >
            {{ btnTxt }}
          </span>
        </div-wrapper>
      </div-wrapper>
    </div-wrapper>
  </div-wrapper>
</template>
<script>
import DivWrapper from '@/components/form/DivWrapper';
import CloseIcon from '@/components/svgIcons/CloseIcon';
export default {
  components: {
    DivWrapper,
    CloseIcon,
  },
  props: {
    btnTxt: {
      required: true,
      type: String,
      default: 'Submit',
    },

    btnClass: {
      required: false,
      type: String,
      default: '',
    },

    boxClass: {
      required: false,
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_modal-box.scss';
</style>
